import {Component, OnInit} from '@angular/core';
import {AnswerService} from '../shared/answer.service'
import {MainService} from '../shared/main.service'

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss']
})
export class AnswerComponent implements OnInit {

  constructor(public answerService: AnswerService, public mainService:MainService) { }

  ngOnInit(): void {
  }

  onAnswer(answer): void
  {
    this.answerService.onAnswer(answer)
  }

}
