import { Component, OnInit } from '@angular/core';
import {RegistrationService} from '../shared/registration.service'

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {
  
  constructor(public registrationService: RegistrationService) { }

  ngOnInit(): void {
  }
  
  onAuth () {
    this.registrationService.authentication()
  }
  
  onRegistration () {
    this.registrationService.registration()
  }
  onEnterCode () {
    this.registrationService.checkConfirmCode()
  }
}
