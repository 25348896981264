import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../shared/main.service'

@Injectable({providedIn: 'root'})
export class AuthService {
  email: string = ''
  pass: string = ''
  sentAuth: boolean = false
  email_error: boolean = false
  pass_error: boolean = false
  nouser_error: boolean = false
  
  constructor(private http: HttpClient, public mainService:MainService) {}
  
  checkErrorFields (err: string[]): void 
  {
    let er = {}
    err.forEach(function(value, key) { er[value]=true });
    
    if (er['email'] === undefined) { this.email_error = false }
    else { this.email_error = true }
    
    if (er['pass'] === undefined) { this.pass_error = false }
    else { this.pass_error = true }
    
    if (er['nouser'] === undefined) { this.nouser_error = false }
    else { this.nouser_error = true }
  }
  
  onAuth() {
    let errors: string[] = []
    if (this.email == '') errors.push('email')
    if (this.pass == '') errors.push('pass')
    
    if (!this.email_error && !this.pass_error)
    {
      this.sentAuth=true
      this.http.post<any>('https://sport-and-me.ru/ajax/auth.php', {email:this.email, pass:this.pass}).subscribe(
        data => {
          if (data['errors']!== undefined) { this.checkErrorFields(data['errors']); }
          else
          {
            if (data['ok'] !== undefined && data['ok'] === true && data['stage'] !== undefined)
            {
              if (data['stage']=='question' && data['n'] !== undefined && data['question'] !== undefined && data['time'] !== undefined && data['answers'] !== undefined) {this.mainService.goQuestion(data['n'], data['question'], data['time'], data['answers']);}
              else if (data['stage']=='wait' && data['start'] !== undefined) { this.mainService.goWait(data['start']);}
              else if (data['stage']=='results' && data['results'] !== undefined) { this.mainService.goResults(data['results']);}
            }
          }
        }
      )
    }
  }
}