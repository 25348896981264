<h2 class="text-center">Регистрация</h2>
<div class="row justify-content-center">
<form class="needs-validation" novalidate="">
      <div class="row">
          <div class="col-md-6 mb-3">
            <label for="Name">Имя</label>
            <input type="text" class="form-control" id="Name" placeholder="" value="" required="" [(ngModel)]="registrationService.name" name="name" [disabled]="registrationService.waitCode">
            <div *ngIf="registrationService.name_error" class="invalid-feedback" style="display: block;">
              Имя обязательное поле для заполнения.
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="email">E-mail</label>
            <input type="email" class="form-control" id="email" placeholder="you@mail.ru" [(ngModel)]="registrationService.email" name="email" [disabled]="registrationService.waitCode">
            <div *ngIf="registrationService.email_error" class="invalid-feedback" style="display: block;">
              Пожалуйста введите email правильно.
            </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-6 mb-3">
            <label for="phone">Пароль</label>
            <input type="password" class="form-control" id="password" placeholder="****" [(ngModel)]="registrationService.pass" name="pass" [disabled]="registrationService.waitCode">
            <div *ngIf="registrationService.pass_error" class="invalid-feedback" style="display: block;">
              Поле пароль нужно заполнить.
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="bdate">Повторите пароль</label>
            <input type="password" class="form-control" id="password2" placeholder="****" [(ngModel)]="registrationService.pass2" name="pass2" [disabled]="registrationService.waitCode">
            <div *ngIf="registrationService.pass2_error" class="invalid-feedback" style="display: block;">
              Пароли не совпадают.
            </div>
          </div>
      </div>
      <div class="row" *ngIf="!registrationService.waitCode">
        <div class="col-md-12">
          <button class="btn btn-primary btn-lg btn-block" type="button" (click)="onRegistration()">Регистрация</button>
        </div>
      </div>
      <div class="row" *ngIf="registrationService.waitCode">
        <div class="col-md-12">
          <label for="bdate">Проверочный код</label>
            <input type="text" class="form-control" id="code" placeholder="1234567" [(ngModel)]="registrationService.code" name="code" [disabled]="registrationService.sentCode">
            <div *ngIf="registrationService.code_error" class="invalid-feedback" style="display: block;">
              Код не верный.
            </div>
        </div>
      </div>
      <div class="row" *ngIf="registrationService.waitCode"><br></div>
      <div class="row" *ngIf="registrationService.waitCode">
        <div class="col-md-12">
          <button class="btn btn-primary btn-lg btn-block" type="button" (click)="onEnterCode()" [disabled]="registrationService.sentCode">Завершить регистрацию</button>
        </div>
      </div>
      <div class="row"><br></div>
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-success btn-lg btn-block" type="button" (click)="onAuth()">Вернуться к авторизации</button>
        </div>
      </div>
</form>
</div>
