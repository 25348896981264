import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../shared/main.service'
import {tap} from 'rxjs/operators'

@Injectable({providedIn: 'root'})
export class RegistrationService {
  
  waitCode: boolean = false
  sentCode: boolean = false
  name: string = ''
  email: string = ''
  pass: string = ''
  pass2: string = ''
  code: string = ''
  name_error: boolean = false
  email_error: boolean = false
  pass_error: boolean = false
  pass2_error: boolean = false
  code_error: boolean = false
  
  constructor(private http: HttpClient, public mainService:MainService) {}
  
  checkErrorFields (err: string[]): void 
  {
    let er = {}
    err.forEach(function(value, key) { er[value]=true });
    
    if (er['name'] === undefined) { this.name_error = false }
    else { this.name_error = true }
    
    if (er['email'] === undefined) { this.email_error = false }
    else { this.email_error = true }
    
    if (er['pass'] === undefined) { this.pass_error = false }
    else { this.pass_error = true }
    
    if (er['pass2'] === undefined) { this.pass2_error = false }
    else { this.pass2_error = true }
    
    if (er['code'] === undefined) { this.code_error = false }
    else { this.code_error = true }
  }
  
  authentication() {
    this.mainService.goAuthentication()
  }
  
  registration(): void {
    let errors: string[] = []
    if (this.pass !== this.pass2) errors.push('pass2')
    if (this.pass == '') errors.push('pass')
    if (this.email == '') errors.push('email')
    if (this.name == '') errors.push('name')
    
    if (errors.length==0)
    {
      this.http.post<any>('https://sport-and-me.ru/ajax/reg.php', {name:this.name, email:this.email, pass:this.pass}).subscribe(
        data => {
          if (data['errors']!== undefined) { this.checkErrorFields(data['errors']); }
          else
          {
            if (data['ok']!== undefined && data['ok'] === true )
            {
              this.waitCode=true
            }
          }
        }
      )
    }
    else this.checkErrorFields(errors);
  }
  
  checkConfirmCode()
  {
    this.sentCode=true
    this.http.post<any>('https://sport-and-me.ru/ajax/reg.php', {code:this.code}).subscribe(
      data => {
        if (data['errors']!== undefined)
        {
          this.checkErrorFields(data['errors'])
          this.sentCode=false
        }
        else
        {
          if (data['ok']!== undefined && data['ok'] === true )
          {
            this.mainService.registrationfinish=true;
            this.authentication()
          }
        }
      }
    )
  }
  
}