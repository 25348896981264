import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class MainService {
  
  auth:boolean = true
  registration:boolean = false
  questions:boolean = false
  registrationfinish:boolean = false
  wait: boolean = false
  result: boolean = false
  checkAuthFlag: boolean = false
  ord: number = 0
  question: string = ''
  answers: string[] = []
  time: number = 0
  timerstart: number = 0
  timecounter: number = 0
  up = NaN
  startDate = new Date()
  results = []
  
  
  constructor(private http: HttpClient) {}
  
  goRegistration(): void {
    this.registration=true
    this.auth=false
    this.questions=false
    this.wait=false
    this.result=false
    clearInterval(this.up)
  }
  goAuthentication(): void {
    this.registration=false
    this.auth=true
    this.questions=false
    this.wait=false
    this.result=false
    clearInterval(this.up)
  }
  updateCounter()
  {
    if (Math.floor(Date.now() / 1000) - this.timerstart <= this.time)
    {
      this.timecounter=this.time - (Math.floor(Date.now() / 1000) - this.timerstart)
    }
    else
    {
      this.checkAuth()
    }
  }
  
  goQuestion(n, question, time, answers)
  {
    if (this.up !==NaN ) {clearInterval(this.up);}
    this.ord=n
    this.question=question
    this.answers=answers
    this.time=time
    this.timecounter=time
    this.timerstart=Math.ceil(Date.now() / 1000)
    this.registration=false
    this.auth=false
    this.questions=true
    this.wait=false
    this.result=false
    this.up=setInterval(() => {this.updateCounter()}, 1000);
  }
  
  goWait(start): void
  {
    this.startDate=new Date(start)
    this.registration=false
    this.auth=false
    this.questions=false
    this.wait=true
    this.result=false
  }
  timeResult(time): string
  {
    if (time % 60<10) return Math.floor(time/60)+':0'+(time % 60)
    else return Math.floor(time/60)+':'+(time % 60)
  }
  maskEmail(email): string
  {
    let r = email.split('@')
    return r[0].substring(0,1)+'*@'+r[1]
  }
  
  goResults(results): void
  {
    this.results=[]
    results.forEach( item => {
      this.results.push({'name':item['name'], 'email': this.maskEmail(item['email']), 'result':item['result'], 'time':this.timeResult(item['time'])})
    });
    this.registration=false
    this.auth=false
    this.questions=false
    this.wait=false
    this.result=true
  }
  
  checkAuth()
  {
    this.checkAuthFlag=true
    this.http.post<any>('https://sport-and-me.ru/ajax/auth.php', {}).subscribe(
        data => {
          if (data['errors']!== undefined) { this.goAuthentication() }
          else
          {
            if (data['ok'] !== undefined && data['ok'] === true && data['stage'] !== undefined)
            {
              if (data['stage']=='question' && data['n'] !== undefined && data['question'] !== undefined && data['time'] !== undefined && data['answers'] !== undefined) {this.goQuestion(data['n'], data['question'], data['time'], data['answers']);}
              else if (data['stage']=='auth') { this.goAuthentication();}
              else if (data['stage']=='wait' && data['start'] !== undefined) { this.goWait(data['start']);}
              else if (data['stage']=='results' && data['results'] !== undefined) { this.goResults(data['results']);}
            }
          }
          this.checkAuthFlag=false
        }
      )
  }
}