<h2 class="text-center">Авторизация</h2>
<div class="row justify-content-center">
<form class="needs-validation" novalidate="">
      <div class="row" *ngIf="mainService.registrationfinish">
        <div class="col-md-12 text-center">
          Поздравляем Вы успешно зарегистрировались!<br/>Для входа введите Ваш E-mail и пароль.
        </div>
      </div>
      <div class="row"><br></div>
      <div class="row">
          <div class="col-md-6 mb-3">
            <label for="email">E-mail</label>
            <input type="email" class="form-control" id="email" placeholder="you@mail.ru" name="email" [(ngModel)]="authService.email" [disabled]="authService.sentAuth">
            <div *ngIf="authService.email_error" class="invalid-feedback" style="display: block;">
              Пожалуйста введите email правильно.
            </div>
            <div *ngIf="authService.nouser_error" class="invalid-feedback" style="display: block;">
              Вы указали неправильный email или пароль.
            </div>
          </div>
          <div class="col-md-6 mb-3">
            <label for="phone">Пароль</label>
            <input type="password" class="form-control" id="password" placeholder="****" name="pass" [(ngModel)]="authService.pass" [disabled]="authService.sentAuth">
            <div *ngIf="authService.pass_error" class="invalid-feedback" style="display: block;">
              Поле пароль нужно заполнить.
            </div>
          </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-primary btn-lg btn-block" type="button" (click)="onLogin()" [disabled]="authService.sentAuth">Войти</button>
        </div>
      </div>
      <div class="row"><br></div>
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-success btn-lg btn-block" type="button" (click)="onRegistration()">Регистрация</button>
        </div>
      </div>
</form>
</div>
