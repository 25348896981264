<div class="col-md-12 text-center">
  <div class="row justify-content-md-center">
<div class="col-md-6 col-xs-12 mb-3">
<h2>Результаты</h2>
<div class="table-responsive">
<table class="table table-striped table-sm">
<thead>
<tr>
  <th>Место</th>
  <th>Имя</th>
  <th>E-mail</th>
  <th>Результат</th>
  <th>Время</th>
</tr>
</thead>
<tbody>
<tr *ngFor="let row of mainService.results; let i = index">
  <td>{{ i+1 }}</td>
  <td>{{ row['name'] }}</td>
  <td>{{ row['email'] }}</td>
  <td>{{ row['result'] }}</td>
  <td>{{ row['time'] }}</td>
</tr>
</tbody>
</table>
</div>
</div>
</div>
</div>