import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MainService} from '../shared/main.service'

@Injectable({providedIn: 'root'})
export class AnswerService {
  
  sentAnswer: boolean = false
  
  constructor(private http: HttpClient, public mainService:MainService) {}
  
  onAnswer(answer)
  {
    this.sentAnswer=true;
    this.http.post<any>('https://sport-and-me.ru/ajax/a.php', {question: this.mainService.ord, answer: answer}).subscribe(
      data => {
        if (data['errors']!== undefined) { this.mainService.goAuthentication() }
        else
        {
          if (data['ok'] !== undefined && data['ok'] === true && data['stage'] !== undefined)
          {
            if (data['stage']=='question' && data['n'] !== undefined && data['question'] !== undefined && data['time'] !== undefined && data['answers'] !== undefined) {this.mainService.goQuestion(data['n'], data['question'], data['time'], data['answers']);this.sentAnswer=false;}
            else if (data['stage']=='auth') { this.mainService.goAuthentication();}
            else if (data['stage']=='wait' && data['start'] !== undefined) { this.mainService.goWait(data['start']);}
            else if (data['stage']=='results' && data['results'] !== undefined) { this.mainService.goResults(data['results']);}
            }
          }
        }
    )
  }
}