import { Component, OnInit } from '@angular/core';
import {AuthService} from '../shared/auth.service'
import {MainService} from '../shared/main.service'

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor(public authService: AuthService, public mainService:MainService) { }

  ngOnInit(): void {
  }
  
  onLogin() {
    this.authService.onAuth()
  }
  
  onRegistration() {
    this.mainService.goRegistration()
  }
}
