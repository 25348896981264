import { Component, OnInit } from '@angular/core';
import {MainService} from '../shared/main.service'

@Component({
  selector: 'app-waiter',
  templateUrl: './waiter.component.html',
  styleUrls: ['./waiter.component.scss']
})
export class WaiterComponent implements OnInit {

  constructor(public mainService:MainService) { }

  ngOnInit(): void {
  }

}
