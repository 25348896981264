<div class="container">
  <div class="py-5 text-center">
    <app-question *ngIf="mainService.questions"></app-question>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-auth *ngIf="mainService.auth"></app-auth>
      <app-waiter *ngIf="mainService.wait"></app-waiter>
      <app-registration *ngIf="mainService.registration"></app-registration>
      <app-answer *ngIf="mainService.questions"></app-answer>
      <app-result *ngIf="mainService.result"></app-result>
    </div>
  </div>
</div>
<nav class="navbar fixed-bottom navbar-expand-sm navbar-dark bg-dark" *ngIf="mainService.questions">
  <p style="color:white;margin:auto;">На ответ осталось: {{ mainService.timecounter }} сек.</p>
</nav>